import getCartQuantity from '../utilities/getCartQuantity'

const addToCart = ({ state, action }) => {
  if (!state.cartList) {
    console.error('Undefined "cart" in state: ', state)
  }

  const index = state.cartList.findIndex((item) => item.productId === action.payload.productId)
  const cartList = [...state.cartList]

  if (index === -1) {
    cartList.push({ ...action.payload })
  } else {
    cartList[index] = {
      ...action.payload,
      quantity: parseInt(state.cartList[index].quantity, 10) + parseInt(action.payload.quantity, 10),
    }

  }

  localStorage.setItem('cartList', JSON.stringify(cartList))

  return {
    ...state,
    cartList,
    cartQuantity: getCartQuantity(cartList),
  }
}

export default addToCart
