import getCartQuantity from '../utilities/getCartQuantity'

const removeFromCart = ({ state, action }) => {
  if (!state.cartList) {
    console.error('Undefined "cart" in state: ', state)
  }

  const cartList = state.cartList.filter((item) => item.productId !== action.payload.productId)

  localStorage.setItem('cartList', JSON.stringify(cartList))

  return {
    ...state,
    cartList,
    cartQuantity: getCartQuantity(cartList),
  }
}

export default removeFromCart
