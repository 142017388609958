const emptyCart = ({ state }) => {
  if (!state.cartList) {
    console.error('Undefined "cart" in state: ', state)
  }

  const cartList = []

  localStorage.setItem('cartList', JSON.stringify(cartList))

  return {
    ...state,
    cartList,
    cartQuantity: 0,
  }
}

export default emptyCart
