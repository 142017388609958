import React from 'react'

import reducer from './reducer'
import initialState from './reducer/initialState'

const CartStateContext = React.createContext()
const CartDispatchContext = React.createContext()

function CartContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <CartStateContext.Provider value={state}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  )
}

function useState() {
  const context = React.useContext(CartStateContext)

  if (context === undefined) {
    throw new Error('useState must be used within a CartProvider')
  }

  return context
}

function useDispatch() {
  const context = React.useContext(CartDispatchContext)

  if (context === undefined) {
    throw new Error('useDispatch must be used within a CartProvider')
  }

  return context
}

function useCartContext() {
  return [useState(), useDispatch()]
}

export { useCartContext, CartContextProvider }
