import getCartQuantity from '../utilities/getCartQuantity'

const getCartFromLocalStorage = () => {
  try {
    const cartList = JSON.parse(localStorage.getItem('cartList'))
    if (Array.isArray(cartList)) {
      return {
        cartList,
        cartQuantity: getCartQuantity(cartList)
      }
    }

    return { cartList: [], cartQuantity: 0 }
  } catch (err) {
    console.error(err.message)
    return []
  }
}

const initialState = { ...getCartFromLocalStorage() }

export default initialState
