import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { CartContextProvider } from './src/context/cart';
import theme from 'styles/theme';

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <CartContextProvider>
        <CssBaseline />
        {element}
      </CartContextProvider>
    </ThemeProvider>
  );
};
