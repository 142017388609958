import addToCart from './addToCart'
import removeFromCart from './removeFromCart'
import setQuantity from './setQuantity'
import emptyCart from './emptyCart'

const reducer = (state, action) => {
  if (!action.payload) {
    console.error(`Undefined "payload" in action ${action.type}`)
    return state
  }

  switch (action.type) {
    case 'ADD_TO_CART':
      return addToCart({ state, action })

    case 'EMPTY_CART':
      return emptyCart({ state, action })

    case 'REMOVE_FROM_CART':
      return removeFromCart({ state, action })

    case 'SET_QUANTITY':
      return setQuantity({ state, action })

    default:
      return state
  }

}

export default reducer
