import getCartQuantity from '../utilities/getCartQuantity'

const setQuantity = ({ state, action }) => {
  if (!state.cartList) {
    console.error('Undefined "cart" in state: ', state)
  }

  const index = state.cartList.findIndex((item) => item.productId === action.payload.productId)

  if (index === -1) {
    return state
  }

  const cartList = [...state.cartList]

  cartList[index].quantity = action.payload.quantity

  localStorage.setItem('cartList', JSON.stringify(cartList))

  return {
    ...state,
    cartList,
    cartQuantity: getCartQuantity(cartList),
  }
}

export default setQuantity
